<template>
  <div class="carousel-container">
    <van-swipe 
      class="my-swipe"
      :autoplay="4000"
      :vertical=false
      lazy-render 
      ref="swiperRef"
      @change="handleChange"
    >
      <van-swipe-item 
        v-for="(image, index) in images" 
        :key="index" 
        @click="handleClick(index)"
      >
        <img 
          :src="image" 
          class="slide-image"
          alt="image"
        />
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
import { onMounted, nextTick, ref } from 'vue';
import { Swipe as VanSwipe, SwipeItem as VanSwipeItem } from 'vant';

export default {
  name: 'SwiperComponent',
  components: {
    VanSwipe,
    VanSwipeItem,
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  emits: ['slide-click', 'change'],
  setup(_, { emit }) {
    const swiperRef = ref(null)

    const handleClick = (index) => {
      // console.log('slide clicked:', index);
      emit('slide-click', index);
    }

    const handleChange = (index) => {
      // console.log('slide changed:', index);
      emit('change', index);
    }

    // onMounted(nextTick(() => {
    //   console.log('swiper mounted')
    //   swiperRef.value.resize()
    // }))

    onMounted(() => {
      console.log('swiper mounted')
      setTimeout(() => {
        swiperRef.value.resize()
      }, 1000)
    })

    return {
      swiperRef,
      handleClick,
      handleChange,
    }
  },
};
</script>

<style lang="stylus">
.carousel-container
  width 100%
  max-width 600px
  margin 0 auto

  .my-swipe
    height 100%

    .van-swipe-item
      display flex
      justify-content center
      align-items center
      height 100%

      .slide-image
        width 100%
        height 100%
        object-fit cover
    .van-swipe__indicator 
      height 8px
      width 8px
      border-radius 50%
      // background-color rgba(255, 255, 255, 0.2)
    .van-swipe__indicator--active
      height 8px
      width 32px
      border-radius 10px
      background-color rgba(255, 255, 255, 0.9)
</style>