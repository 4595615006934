<template>
  <div class="nav-list">
    <div
      class="nav-list-item"
      v-for="(nav, index) in navList"
      :key="index"
      :style="{background: `url(${nav.image[browserType === 'landscape' ? 0 : 1]}) no-repeat`, backgroundSize: '100% auto'}"
      @click="handleClickNav(nav)"
    >
      <div>
        <p class="zh">{{ nav.text }}</p>
        <p class="en">{{ nav.en }}</p>
      </div>
    </div>
    <!-- <div
      class="banner vertical"
      @click="handleClickBanner"
    >
      <img src="https://qncweb.ktvsky.com/20240710/other/a17e4bd56f5d4304b080002e5a634561.png">
    </div> -->
    <Swiper
      v-if="browserType !== 'landscape'"
      class="nav-list-swiper"
      :images="homeBannerImages"
      @slide-click="handleClickBanner"
      @change="handleBannerChange"
    />
  </div>
</template>

<script>
import { computed, ref, onMounted, watch, nextTick } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import useLoginValid from '@/composables/useLoginValid'
import useVip from '@/composables/useVip'
import { useShareBrowserSize } from '@/composables/sharedComposable'
import Toast from '@/utils/toast'
import Swiper from '@/components/swiper/index.vue'
import { sendLog } from '@/directives/v-log/log'
import useQRCode from '@/composables/useQRCode'
import get from 'lodash/get'
import { getCarplayInfo } from '@/service/carplay-info'
import { vipLogFrom } from '@/constants/index'
import { homeBannerImages } from '@/constants/index'
import useActivity from "@/composables/useActivity";


export default {
  name: 'NavList',
  components: {
    Swiper,
  },
  props: {
    // firstSong: {
    //   type: Object,
    //   default: () => {
    //     return {
    //       singer: '',
    //       music_name: '',
    //     }
    //   }
    // },
  },
  setup(props, { emit }) {
    const { browserType } = useShareBrowserSize()
    const { showActivityAiface } = useActivity();

    let navList = ref([
      {
        text: '歌手点歌',
        en: '', // 王心凌、周杰伦、薛之谦、张学友...
        image: [
          'https://qncweb.ktvsky.com/20240902/vadd/8040ad5d2d773ce289b02841a7ccb0f7.png',
          'https://qncweb.ktvsky.com/20240902/vadd/e694d7bfa6f41790392030f6ccc6331c.png',
        ],
        pathName: 'singer',
        isSupport: true,
        type: 'page'
      },
      {
        text: '快唱高潮',
        en: '', // 爆款热歌 副歌唱到爽
        image: [
          'https://qncweb.ktvsky.com/20240902/vadd/8163b8fdecc76995a060d498d8eda64f.png',
          'https://qncweb.ktvsky.com/20240902/vadd/16092c33b7e70d84bcc39fd3dc4bc3c4.png',
        ],
        pathName: 'climax',
        isSupport: true,
        type: 'page',
        isCheckLogin: true
      },
      {
        text: 'DJ现场',
        en: '',
        image: [
          'https://qncweb.ktvsky.com/20240902/vadd/69dec5bacb0f35c17d3b83e6b0a6a73d.png',
          'https://qncweb.ktvsky.com/20240902/vadd/22d99c3a54314f0f543f1766c2019c20.png',
        ],
        pathName: 'album',
        isSupport: true,
        type: 'page',
        isCheckLogin: false
      },
    ]);

    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const { showLoginQrcode, isLogin } = useLoginValid()
    const { showVipQrcode } = useVip()
    const userInfo = computed(() => store.state.userInfo)
    const vipInfo = computed(() => store.state.vipInfo)
    const isVip = computed(() => !!vipInfo.value.end_time)
    const userType = computed(() => store.state.userInfo.userType)
    const end_time = computed(() => isVip.value ? vipInfo.value.end_time.split(' ')[0] : '')
    const mvIsHide = computed(() => store.state.mvIsHide)
    const { getQRCodeURL } = useQRCode()
    let qrCodeURL = ref('https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png')
    const isFinish = computed(() => store.state.climax.freeSing)
    const preferentialTime = ref(30 * 60 * 1000)
    const timeData = ref({})

    // const freeVipNumber =computed(() => Number(store.state.freeVipNumber))
    const userTime = ref(null)

    const getLoginQrcode = async () => {
      store.dispatch('startCheckLoginStatus');
      const { data } = await getCarplayInfo()
      if (get(data, 'pay_qr', '')) {
        const qrCodeData = await getQRCodeURL(`${data.pay_qr}&fr=mine&log=${vipLogFrom.get('扫码登录-我的')}`)
        if (qrCodeData) {
          qrCodeURL.value = qrCodeData
        }
      }
    }

    const handleClickNav = (nav) => {
      // eventBus.emit('handle-click-nav',nav)
      if (nav.isSupport) {
        emit('click-nav', nav)
        if (nav.pathName === 'singer') {
          sendLog({
            event_type: '10000~50000',
            event_name: 10009,
            event_data: {
              str1: '首页',
              str2: '歌星',
              str3: '进入歌星页',
              str4: 'click',
            },
          })
        }
        if (nav.pathName === 'album') {
          // album
          sendLog({
            event_type: '10000~50000',
            event_name: 30269,
            event_data: {
              str1: '首页',
              str2: '车载DJ',
              str3: '点击',
              str4: 'click',
            },
          })
        }
        return
      }
      Toast('此功能暂未完成移植');
    }

    const handleGoMine = () => {
      // console.log('handleGoMine')
      sendLog({
        event_type: 'click',
        event_name: 210,
      })
      if (!isLogin.value) {
        // showVipQrcode({
        //   log: '我的入口-头像'
        // })
        showLoginQrcode({
          log: '我的入口-头像'
        })
        return
      }
      router.push({
        name: 'mine'
      })
      sendLog({
        event_type: '10000~50000',
        event_name: 10010,
        event_data: {
          str1: '首页',
          str2: '我的入口',
          str3: '进入我的',
          str4: 'click',
        },
      })
    }

    const handleOpenVip = () => {
      sendLog({
        event_type: 'click',
        event_name: 201,
        event_data: {
          type: 4
        }
      })
      showVipQrcode({
        log: '我的入口-头像'
      })
      sendLog({
        event_type: '10000~50000',
        event_name: 10011,
        event_data: {
          str1: '首页',
          str2: '我的开通会员',
          str3: '点击开通会员',
          str4: 'click',
        },
      })
    }
    watch(
      () => route,
      (val) => {
        // if(val.name == 'climax'){
        //   nextTick(()=>{
        //     userTime.value.start()
        //   })
        // }else{
        //   nextTick(()=>{userTime.value.pause()})
        // }
      },{
        deep:true,
        immediate: true
      });
    watch(isLogin, (val) => {
      if (!val) {
        getLoginQrcode()
      }
    }, { immediate: true })

    const handleTimeFinish = ()=>{
      store.dispatch('climax/updateFreeSing', true)
    }

    // const handleClickBanner = () => {
    //   sendLog({
    //     event_type: '10000~50000',
    //     event_name: 30235,
    //     event_data: {
    //       str1: '首页',
    //       str2: '“特别定制”歌单',
    //       str3: '点击',
    //       str4: 'click',
    //       str5: isLogin.value ? '已登录' : '未登录',
    //       str7: userType.value,
    //     }
    //   })
    //   router.push({
    //     name: 'songList',
    //     query: {
    //       name: '“特”别定制，会员专享歌单',
    //       image: 'https://qncweb.ktvsky.com/common-web/operation_cms/1691133786343.png',
    //     },
    //   })
    // }

    const handleClickBanner = (index) => {
      console.log('banner:', index)
      if (index === 0) {
        sendLog({
          event_type: '10000~50000',
          event_name: 30235,
          event_data: {
            str1: '首页',
            str2: '“特别定制”歌单',
            str3: '点击',
            str4: 'click',
            str5: isLogin.value ? '已登录' : '未登录',
            str7: userType.value,
          }
        })
        router.push({
          name: 'songList',
          query: {
            name: '“特”别定制，会员专享歌单',
            image: 'https://qncweb.ktvsky.com/common-web/operation_cms/1691133786343.png',
          },
        })
      } else if (index === 1) {
        console.log('aimv')
        sendLog({
          event_type: '10000~50000',
          event_name: 20290,
          event_data: {
            str1: '首页',
            str2: '换脸MV',
            str3: '点击',
            str4: 'click',
          }
        })
        showActivityAiface({})
      } else {
        console.log('no event need to do')
      }

    }

    const handleBannerChange = (e) => {
      // console.log('banner change:', e)
      if (e === 0 || route.name !== 'home') return

      if (!mvIsHide.value) return

      sendLog({
        event_type: '10000~50000',
        event_name: 30257,
        event_data: {
          str1: '首页',
          str2: '轮播banner位-麦克风售卖',
          str3: '展示',
          str4: 'show',
          // str5: isLogin.value ? '已登录' : '未登录',
          // str7: userType.value,
        }
      })
    }

    onMounted(() => {
      if (!isLogin.value) {
        getLoginQrcode()
      }
      // eventBus.on("handle-time-finish", handleTimeFinish);
    })

    return {
      browserType,
      navList,
      userInfo,
      isLogin,
      isVip,
      end_time,
      homeBannerImages,
      handleGoMine,
      handleOpenVip,
      handleClickNav,
      handleTimeFinish,
      qrCodeURL,
      isFinish,
      preferentialTime,
      timeData,
      // freeVipNumber,
      userTime,
      handleClickBanner,
      handleBannerChange,
    }
  },
}
</script>

<style lang="stylus" scoped>
.nav-list
  display flex
  justify-content space-between
  flex-direction row
  color #ffffff
  margin-bottom 0px
  width 808px
  height 334px
  flex-flow nowrap
  .banner
    width 352px
    height 268px
    border-radius 26px
    overflow hidden
  @media screen and (max-width 1200px) and (min-height 1200px)
    width 100%
    height 280px
    flex-direction row
    margin-top 32px
    margin-bottom 32px
  &-item
    // width 388px
    width 248px
    height 334px
    border-radius 24px
    margin-bottom 24px
    & > div
      // width 368px
      width 232px
      // height 113px
      height 82px
      // background #0000000D
      // backdrop-filter blur(300px)
      // padding 0 0 0 10px
      // padding 0 0 0 8px
      display flex
      flex-direction column
      justify-content center
      align-items center
      // border-radius 32px
      // margin-left 10px
      // margin-top 211px
      margin-top 244px
    .zh
      font-size 28px
      color #ffffff
      font-weight bold
    .en
      font-size 22px
      color #FFFFFF80
      position relative
      top -2px
    @media screen and (max-width 1200px) and (min-height 1200px)
      // width 352px
      // height 280px
      width 205px
      height 268px
      margin 0px
      background-size 100% auto
      &:nth-child(2)
        margin 0 32px
      & > div
        // width: 338px;
        // height: 90px;
        // margin 183px auto 0
        width 186px
        height 64px
        margin 196px auto 0
      .zh
        font-size 22px
        margin-top 0px
      .en
        font-size 17px
  &-user
    width 560px
    height 320px
    display flex
    flex-direction column
    align-items center
    position relative
    background url('https://qncweb.ktvsky.com/20240604/vadd/65ba08fdf76c11ddd703918673996bb3.png') no-repeat
    background-size 100% 100%
    &-top-img
      width 110px
      height 110px
      border-radius 100%
      overflow hidden
      // margin 40px 0 12px
      margin 0px 0 28px
      img
        width 100%
        height 100%
    &-vip
      width 50px
      height 26px
      position absolute
      top 60px
      right 20px
      img
        width 100%
        height 100%
    &-name
      margin-bottom 13px
      &-free
        position absolute
        top 175px
        left -10px
      &-time
        position absolute
        left 335px
        top 180px
        .colon
          display inline-block
          margin 0 4px
          color #ffffff
        .block
          display inline-block
          width 30px
          height 30px
          border-radius 4px
          text-align center
          line-height 32px
          color #ffffff
          font-size 20px
          font-weight 800
          background rgba(0, 100, 114, 0.5)
      p
        width 439px
        text-align center
        &:nth-child(1)
          font-size 28px
          font-weight 400
          color #fff
          letter-spacing 0
          height 33px
          line-height 33px
          max-width 439px
          white-space nowrap
          overflow hidden
          text-overflow ellipsis
        &:nth-child(2)
          font-size 22px
          font-weight 400
          color #fff
          opacity 0.8
          margin-top 8px
          height 26px
          line-height 26px
    &-button
      width 180px
      height 60px
      margin-top 13px
      background linear-gradient(90deg, #FFDAB3 -39.21%, #C69156 137.77%)
      border-radius 40px
      font-size 24px
      font-weight 400
      color #000
      letter-spacing 0
      display flex
      align-items center
      justify-content center
    &-taste
      position absolute
      right 30px
      bottom 10px
    &-finish
      width 100%
      height 80px
      line-height 80px
      background url('https://qncweb.ktvsky.com/20240604/vadd/4c2809ecebcaf1f691cae7946b0f544d.png') no-repeat
      background-size 100% 100%
      position absolute
      bottom 0px
      font-size 22px
      color rgba(255, 255, 255, 1)
      &-tip
        margin-left 20px
      &-zero
        font-size 30px
        margin-left 10px
    @media screen and (max-width 1200px) and (min-height 1100px)
      width 344px
      height 230px
      border-radius 10px
      background-size 100% auto !important
      background-position bottom center !important
      &-top-img
        width 60px
        height 60px
        margin 28px 0 14px
      &-vip
        width 40px
        height 21px
        top 80px
        right 10px
      &-name
        p
          width 308px
          &:nth-child(1)
            font-size 22px
            height 26px
            line-height 26px
            max-width 308px
          &:nth-child(2)
            font-size 18px
            margin-top 4px
            height 21px
            line-height 21px
      &-button
        width 131px
        height 42px
        margin-top 11px
        border-radius 28px
        font-size 20px
  .active-vip
    .nav-list-user-top-img
      width 120px
      height 120px
      margin 54px 0 24px
      @media screen and (max-width 1200px) and (min-height 1100px)
        width 76px
        height 76px
        margin 40px 0 20px
    .nav-list-user-name
      p
        &:nth-child(1)
          height 38px
          line-height 38px
          font-size 32px
          color #E3AB5D
        &:nth-child(2)
          height 28px
          line-height 28px
          font-size 24px
          margin-top 16px
          opacity 0.6
        @media screen and (max-width 1200px) and (min-height 1100px)
          &:nth-child(1)
            height 26px
            line-height 26px
            font-size 22px
          &:nth-child(2)
            height 21px
            line-height 21px
            font-size 18px
            margin-top 10px
  &-unlogin
    width 560px
    height 320px
    background #2E3033
    border-radius 10px
    padding 195px 0 0 42px
    background url(https://qncweb.ktvsky.com/20240415/other/bec3639d74b890d8744dd756675c7858.png) no-repeat
    background-size 100% 100%
    position relative
    &-left
      p
        &:nth-child(1)
          width auto
          height 49px
          line-height 49px
          font-size 34px
          font-weight 400
          margin-bottom 8px
        &:nth-child(2)
          height 28px
          line-height 28px
          font-size 24px
          color rgba(255, 255, 255, 0.80)
          letter-spacing 2px
    &-right
      width 150px
      height 150px
      background #fff
      display flex
      justify-content center
      align-items center
      border-radius 6px
      position absolute
      top 63px
      right 71px
      img
        width 150px
        height 150px
        padding 0px
        max-width unset
    @media screen and (max-width 1200px) and (min-height 1100px)
      width 344px
      height 230px
      padding 144px 0 0 24px
      background-size 100% auto
      background-position bottom center
      &-left
        p
          &:nth-child(1)
            height 35px
            line-height 35px
            font-size 30px
            margin-bottom 6px
          &:nth-child(2)
            height 21px
            line-height 21px
            font-size 18px
      &-right
        width 90px
        height 90px
        top 75px
        right 45px
        img
          width 90px
          height 90px
  &-swiper
    display none
    @media screen and (max-width 1200px) and (min-height 1100px)
      display block
      width 352px
      height 280px
      border-radius 24px
      overflow hidden
</style>
