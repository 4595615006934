<template>
  <div class="song">
    <div class="num" :class="{ ordered: isOrdered }">{{ index + 1 }}</div>
    <div class="song-item" :class="className">
      <div class="left" :class="{ ordered: isOrdered }" @click="handleOrder">
        <div class="name">
          <span class="name-txt">{{ songItem.music_name }}</span>
        </div>
        <div class="desc">
          <template v-if="songItem.singer">
            <span
              class="author"
              :class="[singerCanClick ? 'clickable' : '']"
              @click.stop="handleClickSinger"
              >{{ songItem.singer }}</span
            >
          </template>
          <img
            v-if="songItem.is_vip"
            class="vip-icon"
            src="https://qncweb.ktvsky.com/20231206/vadd/56dc0bc6477bf2c7a6c4fcdc8360804e.png"
            alt=""
          />
          <i v-else class="free-icon">免费</i>
          <span class="sing-cnt" v-if="songItem.sing_cnt"
            >演唱{{ songItem.sing_cnt }}次</span
          >
        </div>
      </div>
      <div class="right">
        <svg
          @click.stop="handlePlay('play')"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.9" clip-path="url(#clip0_31_907)">
            <circle
              cx="25.7222"
              cy="14.2403"
              r="9.27787"
              stroke="white"
              style="stroke: white; stroke-opacity: 1"
              stroke-width="2"
            />
            <path
              d="M17.1572 16.9525L5.44516 31.8946L8.67518 35.1246L23.6173 23.4126"
              stroke="white"
              style="stroke: white; stroke-opacity: 1"
              stroke-width="2"
            />
          </g>
          <defs>
            <clipPath id="clip0_31_907">
              <rect
                width="40"
                height="40"
                fill="white"
                style="fill: white; fill-opacity: 1"
              />
            </clipPath>
          </defs>
        </svg>
        <svg
          class="order-icon"
          @click.stop="handlePlay('order')"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.9">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19 21V35H21V21H35V19H21V5H19V19H5V21H19Z"
              fill="white"
              style="fill: white; fill-opacity: 1"
            />
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject, toRefs } from "vue";
import { useStore } from "vuex";
import useSongItem from "@/composables/useSongItem";
import useOrder from "@/composables/useOrder";
import { sendLog } from "@/directives/v-log/log";
import get from "lodash/get";
import { useRouter, useRoute } from "vue-router";
import split from "lodash/split";

export default {
  name: "SongItem",
  props: {
    className: {
      type: String,
      default: "",
    },
    songItem: Object,
    index: Number,
    startPosition: {
      type: Number,
      default: 0,
    },
    logFrom: {
      type: Object,
      default: () => {},
    },
    ponitActionLog: {
      type: Object,
      default: () => {},
    },
    singerEnable: {
      // mv页情况下，歌手名点击是否可以使用
      type: Boolean,
      default: true,
    },
    renderType: {
      default: "list", // list:列表形态 block：块状形态
      type: String,
    },
    isStickButton: {
      default: false,
      type: Boolean,
    },
    show1080Icon: {
      default: false,
      type: Boolean,
    },
    showNewSongIcon: {
      default: false,
      type: Boolean,
    },
  },
  emits: ["singer-click", "order-click"],
  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    const { songItem, logFrom, ponitActionLog, singerEnable, showNewSongIcon } =
      toRefs(props);
    const store = useStore();
    const orderedSongIdMap = inject("orderedSongIdMap");
    const { orderSong } = useSongItem();
    const { orderSong: immediatePlay, orderedList } = useOrder();
    const userInfo = computed(() => store.state.userInfo);
    const vipInfo = computed(() => store.state.vipInfo);
    const isOrdered = computed(() => {
      return orderedSongIdMap.value[songItem.value.songid];
    });
    const mvIsHide = computed(() => store.state.mvIsHide);
    const orderedListNumber = computed(() => orderedList.value.length);
    const singerCanClick = computed(
      () =>
        (mvIsHide.value && !route.query.singerid) ||
        (!mvIsHide.value && singerEnable.value)
    );

    // 处理同一事件既有点歌又有置顶的情况
    const filterPALog = (type) => {
      if (get(ponitActionLog.value, "event_type") !== "10000~50000") return {};
      const eventAction = [
        "进入常唱",
        "进入歌名点歌",
        "进入任意歌手",
        "歌曲列表",
      ];
      let res = {
        ...ponitActionLog.value,
      };
      if (eventAction.includes(get(res, "event_data.str3"))) {
        if (type === "order") {
          res.event_data.str5 = "点歌";
          res.event_data.str6 = "click";
        } else if (type === "stick") {
          res.event_data.str7 = "置顶点歌";
          res.event_data.str8 = "click";
        }
      }
      return res;
    };

    const handleOrder = (
      _,
      v = {
        type: "order",
      }
    ) => {
      // console.log('handleOrder', v.type, v.type === 'order')
      if (v.type === "order") emit("order-click");
      const filterPALogData = filterPALog("order");
      store.commit("UPDATE_CONTROL_FROM_TYPE", 1);
      const isValid1080p = [20179, 3211].includes(logFrom.value.song_list_id);
      if (Object.keys(orderedSongIdMap.value).length === 0) {
        orderSong(songItem.value, {
          from: logFrom.value,
          ponitActionLog: filterPALogData,
          isValidNewSong: showNewSongIcon.value,
          isValid1080p,
          isNotSingImmediately: v.type === "order",
        });
        return;
      }
      orderSong(songItem.value, {
        immediate: false,
        from: logFrom.value,
        ponitActionLog: filterPALogData,
        isValidNewSong: showNewSongIcon.value,
        isValid1080p,
        isNotSingImmediately: v.type === "order",
      });
    };

    const handleClickSinger = (e) => {
      if (!singerCanClick.value) {
        return;
      }
      e.stopPropagation();
      sendLog({
        event_type: "10000~50000",
        event_name: 10108,
        event_data: {
          str1: "任意点歌页",
          str2: "歌曲列表",
          str3: "点击任意歌手",
          str4: "click",
        },
      });
      if (mvIsHide.value) {
        router.push({
          name: "songList",
          query: {
            name: split(songItem.value.singer, ",")[0],
            image: songItem.value.singer_head,
            singerid: songItem.value.singerid,
          },
        });
      } else {
        emit("singer-click", {
          singer: split(songItem.value.singer, ",")[0],
          singerhead: songItem.value.singer_head,
          singerid: songItem.value.singerid,
        });
        sendLog({
          event_type: "10000~50000",
          event_name: 10109,
          event_data: {
            str1: "MV点歌页",
            str2: "歌曲列表",
            str3: "点击任意歌手",
            str4: "click",
          },
        });
      }
    };

    const handlePlay = async (type) => {
      await Promise.all([
        handleOrder(null, {
          type,
        }),
      ]);
      if (type === "play") {
        sendLog({
          event_type: "10000~50000",
          event_name: 30239,
          event_data: {
            str1: "通用",
            str2: "立即演唱",
            str3: "点击",
            str4: "click",
          },
        });
        immediatePlay(songItem.value, orderedListNumber.value - 1);
      }
    };

    return {
      isOrdered,
      userInfo,
      vipInfo,
      handleOrder,
      handleClickSinger,
      singerCanClick,
      handlePlay,
    };
  },
};
</script>

<style lang="stylus" scoped>
.song
  height fit-content
  display flex
  align-items center
  height 104px
  @media screen and (max-width 1200px) and (min-height 1200px)
    height 94px
  .num
    width 52px
    height 52px
    display flex
    align-items center
    justify-content center
    color #FFFFFFCC
    font-size 36px
    font-family YouSheBiaoTiHei
    margin-right 24px
    font-weight 700
    font-style italic
    &.ordered
      color rgba(219, 174, 106, 1) !important
    @media screen and (max-width 1200px) and (min-height 1200px)
      width 46px
      height 46px
      font-sise 34px
  &-item
    flex 1
    height 104px
    display flex
    align-items center
    justify-content space-between
    color #ffffff
    color rgba(255,255,255,0.7)
    border none !important
    .left
      flex 1
      display flex
      flex-direction column
    .name
      font-size 28px
      color #FFFFFF
      margin-bottom 1px
      display flex
      align-items center
      @media screen and (max-width 1200px) and (min-height 1200px)
        font-size 25px
      &-txt
        white-space nowrap
        overflow hidden
        text-overflow ellipsis
        max-width 350px
    .desc
      display flex
      align-items center
      height 33px
      font-size 22px
      color rgba(255,255,255,0.4)
      max-width 360px
      @media screen and (max-width 1200px) and (min-height 1200px)
        font-size 20px
      .clickable
        position relative
        padding-right 26px
        &::after
          content ""
          position absolute
          right 0
          top 50%
          margin-top -10px
          width 22px
          height 22px
          background url('https://qncweb.ktvsky.com/20231212/vadd/70dbe52816b882ae1f6871b3a509f375.png') no-repeat
          background-size 100% 100%
      .divider
        margin -4px 16px 0 16px
      .vip-icon
        width 50px
        height 26px
        margin-left 13px
        @media screen and (max-width 1200px)
          width 42.3px
          height auto
          margin-left 8px
      .free-icon
        width 56px
        height 26px
        display flex
        align-items center
        justify-content center
        background: linear-gradient(90deg, #009BCB -0.89%, #3D73FF 99.11%);
        color #fff
        font-size 18px
        border-radius 100px
        margin-left 11px
      .sing-cnt
        margin-left 12px
        font-size 16px
        color #38C097
        border: 2px solid #38C097
        border-radius 100px
        padding 0px 12px
        white-space nowrap
        @media screen and (max-width 1200px) and (min-height 1200px)
          margin-left 10px
          font-size 14px
          padding 2px 10px
      .author
        overflow hidden
        white-space nowrap
        text-overflow ellipsis
        span
          overflow hidden
          white-space nowrap
          text-overflow ellipsis
     .ordered
      .name
        color rgba(219, 174, 106, 1) !important
      .desc
        color rgba(219, 174, 106, 1) !important
      .clickable::after
        content ""
        background-image url('https://qncweb.ktvsky.com/20231212/vadd/4a5a96b4a7e0b0ae7f364679f5f69417.png')
    .right
      display flex
      align-items center
      margin-right 30px
      svg
        width 40px
        height 40px
        &:first-child
          margin-right 80px
    @media screen and (max-width 1200px)
      .name
        &-txt
          font-size: 26px;
      .desc
        font-size: 20px;
      .right
        svg
          width 36px
          height 36px
          &:first-child
            margin-right 72px
</style>
