<template>
  <div class="rs-sidebar" :class="{ 'is-active': isActive }">
    <div class="rs-sidebar-header">
      <button @click.stop="toggleSidebar" class="close-btn">
        收起
        <img
          src="https://qncweb.ktvsky.com/20240822/vadd/389de39cc490e4db809e7d098c7e009d.png"
          alt=""
        />
      </button>
      <!-- v-if="isShowHint" -->
      <span class="title">
        <!-- {{ countdown }}s后将自动为您滑到下一页 -->
        自动为您滑动到下一页
        <!-- <img
          @click.stop="closeHint"
          src="https://qncweb.ktvsky.com/20240822/vadd/39c83a987c13ef904179e4dc04f36569.png"
          alt=""
        /> -->
      </span>
    </div>
    <div class="rs-sidebar-content" v-if="songLists.length">
      <Item
        :songLists="songLists"
        @singer-click="handleClickSinger"
        @get-action="getAction"
      />
      <!-- <div class="rs-sidebar-content-grid">
        <template v-for="song in paginatedSongs" :key="song.id">
          <SongItem :song="song" @select="selectSong" />
        </template>
      </div>
      <button @click="prevPage" :disabled="currentPage === 1">上一页</button>
      <button @click="nextPage" :disabled="currentPage === totalPages">
        下一页
      </button> -->
    </div>
  </div>
</template>

<script>
import { ref, computed, toRefs, onMounted, watch } from "vue";
import { useStore } from "vuex";
// import SongItem from "@/components/song-item";
import Item from "./item";
import useRecommend from "@/composables/useRecommend";
import {
  setCloseRecommendTag,
  getCloseRecommendTag,
} from "@/utils/historyCache";
import { sendLog } from "@/directives/v-log/log";

export default {
  components: { Item },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const { isActive } = toRefs(props);
    const store = useStore();
    const { showRecommendConfirm } = useRecommend();
    // const isShowHint = ref(true);
    let isHasControl = ref(false);

    // const songLists = ref([]);
    const songLists = computed(() => store.state.recommend.recommendTop100);

    // const songs = ref([]);
    // const currentPage = ref(1);
    // const itemsPerPage = 16;

    // const totalPages = computed(() =>
    //   Math.ceil(songs.value.length / itemsPerPage)
    // );
    // const paginatedSongs = computed(() => {
    //   const start = (currentPage.value - 1) * itemsPerPage;
    //   return songs.value.slice(start, start + itemsPerPage);
    // });

    // const closeHint = () => {
    //   clearInterval(countdownInterval);
    //   isShowHint.value = false;
    //   sendLog({
    //     event_type: "10000~50000",
    //     event_name: 30261,
    //     event_data: {
    //       str1: "首页",
    //       str2: "智能推荐-关闭自动下一页",
    //       str3: "点击",
    //       str4: "click",
    //     },
    //   });
    // };

    const getAction = (v) => {
      console.log(v);
      isHasControl.value = v;
      sendLog({
        event_type: "10000~50000",
        event_name: 30260,
        event_data: {
          str1: "首页",
          str2: "智能推荐-点歌",
          str3: "点击",
          str4: "click",
        },
      });
    };

    const toggleSidebar = () => {
      sendLog({
        event_type: "10000~50000",
        event_name: 30259,
        event_data: {
          str1: "首页",
          str2: "智能推歌-收起",
          str3: "点击",
          str4: "click",
        },
      });
      emit("close");
      if (isHasControl.value) return;
      const closeTag = getCloseRecommendTag();
      if (closeTag > 2) return;
      if (closeTag === 2) {
        //3次无操作
        showRecommendConfirm();
        sendLog({
          event_type: "10000~50000",
          event_name: 30262,
          event_data: {
            str1: "首页",
            str2: "智能推荐-设置弹窗",
            str3: "展示",
            str4: "show",
          },
        });
      }
      setCloseRecommendTag(closeTag + 1);
    };

    const handleClickSinger = () => {};

    // const nextPage = () => {
    //   if (currentPage.value < totalPages.value) {
    //     currentPage.value++;
    //   }
    // };

    // const prevPage = () => {
    //   if (currentPage.value > 1) {
    //     currentPage.value--;
    //   }
    // };

    // const selectSong = (song) => {
    //   // Handle song selection
    // };

    // // Fetch songs from API
    // const fetchSongs = async () => {
    //   // Fetch logic here
    //   songs.value = await fetch("/api/songs").then((res) => res.json());
    // };

    // fetchSongs();

    // 需求变更不要了
    // let countdown = ref(10);
    // let countdownInterval = null;

    // const startCountdown = () => {
    //   countdown.value = 10;
    //   countdownInterval = setInterval(() => {
    //     if (countdown.value > 0) {
    //       countdown.value--;
    //     } else {
    //       clearInterval(countdownInterval);
    //       closeHint();
    //     }
    //   }, 1000);
    // };

    // onMounted(() => {
    //   startCountdown();
    // });

    // watch(isActive, (val) => {
    //   startCountdown();
    // });

    return {
      // countdown,
      // isShowHint,
      songLists,
      // paginatedSongs,
      // currentPage,
      // totalPages,
      // closeHint,
      toggleSidebar,
      handleClickSinger,
      getAction,
      // nextPage,
      // prevPage,
      // selectSong,
    };
  },
};
</script>

<style lang="stylus" scoped>
.rs-sidebar
  position fixed
  bottom 0
  left 0
  z-index 11
  width 100%
  height 383px
  padding 20px 40px 0 40px
  // background rgba(0, 0, 0, 0.5)
  // box-shadow 0px -2px 20px 0px rgba(0, 0, 0, 0.25)
  background url(https://qncweb.ktvsky.com/20240822/vadd/baa5aa7d5183d49008b98b4c770b4ac5.png)
  background-size cover
  background-position bottom
  background-repeat no-repeat
  transition transform 0.3s ease
  transform translateY(100%)
  &.is-active
    transform translateY(0)
  &-header
    display flex
    justify-content space-between
    padding 0
    position absolute
    top 20px
    left 0
    width 100vw
    padding 0 40px
    z-index 2
    .close-btn
      display flex
      align-items center
      font-size 28px
      color rgba(255, 255, 255, 1)
      img
        width 32px
        height 32px
        margin-left 6px
    .title
      display flex
      align-items center
      font-size 28px
      color rgba(255, 255, 255, 0.4)
      font-weight 400
      // width 395px
      width 299px
      height 33px
      img
        width 32px
        height 32px
        margin-left 10px
  &-content
    // overflow hidden
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    padding 0 40px
    &-grid
      display grid
      grid-template-rows repeat(4, 1fr)
      grid-template-columns repeat(4, 1fr)
      gap 10px
  @media screen and (max-width 1200px) and (min-height 1200px)
    display none
</style>
