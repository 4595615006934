<template>
  <van-swipe
    class="recommend-swipe"
    :autoplay="10000"
    :vertical="false"
    lazy-render
    ref="swiperRef"
  >
    <van-swipe-item v-for="(page, index) in pages" :key="index">
      <div class="recommend-swipe-item">
        <template v-for="(song, i) in page" :key="i">
          <SongItem
            isShowPlayBtn
            isShowOrderBtn
            renderType="block"
            :isStickButton="false"
            class="recommend-list-item"
            :songItem="song"
            :log-from="{
              fr1: 1882,
              fr2: '首页-智能推荐',
            }"
            @singer-click="handleClickSinger"
            @order-click="handleOrderAction"
          />
        </template>
      </div>
    </van-swipe-item>
    <template #indicator="{ active }">
      <div class="custom-indicator">
        <span
          v-for="(_, ind) in indicators"
          :key="ind"
          :class="[active === ind && 'active']"
        >
          {{ ind + 1 }}
        </span>
      </div>
    </template>
  </van-swipe>
</template>

<script>
import { ref, computed, toRefs, onMounted } from "vue";
import SongItem from "@/components/song-item";
import { Swipe as VanSwipe, SwipeItem as VanSwipeItem } from "vant";

export default {
  components: {
    SongItem,
    VanSwipe,
    VanSwipeItem,
  },
  props: {
    songLists: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["get-action", "singer-click"],
  setup(props, { emit }) {
    const swiperRef = ref(null);
    const { songLists } = toRefs(props);

    const pages = computed(() => {
      const result = [];
      if (!songLists.value.length) return result;
      for (let i = 0; i < songLists.value.length; i += 8) {
        result.push(songLists.value.slice(i, i + 8));
      }
      return result;
    });

    const indicators = computed(() => pages.value.length);

    const handleClickSinger = (data) => {
      emit("singer-click", data);
    };

    const handleOrderAction = () => {
      emit("get-action", true);
    };

    onMounted(() => {
      console.log("swiper mounted");
      setTimeout(() => {
        swiperRef.value.resize();
      }, 2000);
    });

    return {
      swiperRef,
      pages,
      indicators,
      handleClickSinger,
      handleOrderAction,
    };
  },
};
</script>

<style lang="stylus" scoped>
.recommend-swipe
  position relative
  width 1840px
  height 383px
  padding-top 73px
  &-item
    display grid
    grid-template-columns repeat(4, 1fr)
    grid-template-rows repeat(2, 1fr)
    gap 20px
    .recommend-list-item
      width 442px
      height 135px
      ::v-deep .song-block
        width 442px
        height 135px
        margin-bottom 0
        .name
          max-width 230px
  .custom-indicator
    display flex
    align-items center
    justify-content space-between
    width 752px
    height 33px
    position absolute
    left 50%
    transform translateX(-50%)
    top 20px
    span
      display block
      // float left
      margin-right 5px
      padding 2px 5px
      font-size 28px
      color rgba(255, 255, 255, 0.2)
      // background rgba(0, 0, 0, 0.1)
    .active
      color rgba(255, 255, 255, 1)
</style>
