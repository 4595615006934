import httpV2 from '@/utils/httpV2'
import get from 'lodash/get'

export async function getHotSongList({p, unionid, version}) {
  let params = {
    p,
    size: 50,
    type: '热门歌曲，总有一首你会唱',
    unionid
  }
  if (version && p > 1) {
    params = {
      ...params,
      ver: version
    }
  }
  const res = await httpV2.get('/stb/v2/often-and-hot/songlist', { params })
  if (p <= 1) {
    return {
      data: [...get(res, 'data', [])],
      version: get(res, 'version', {})
    }
  }
  return {
    data: get(res, 'data', []),
    version: get(res, 'version', {})
  }
}

export async function getNewSongList({p, version}) {
  let params = {
    p,
    size: 20,
  }
  if (version && p > 1) {
    params = {
      ...params,
      ver: version
    }
  }
  const res = await httpV2.get('/stb/v2/qiangxian', { params })
  if (p <= 1) {
    return {
      data: [...get(res, 'data.data_list', [])],
      version: get(res, 'version', '')
    }
  }
  return {
    data: get(res, 'data.data_list', []),
    version: get(res, 'version', '')
  }
}